import React from 'react';
import styled from 'styled-components';
import { LargeContainer } from './common/largeContainer';
import Box from './gameItem';
import { sizes } from './utils';

import seonadh from '../images/Seonadh.jpg';
import critComf from '../images/CritComf.jpg';
import fishing from '../images/Fishing.jpg';

const ListWrapper = styled.div`
	max-width: 1200px;
	padding: 0 50px;
	flex-shrink: 1;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	${[ sizes.down('mobBreak') ]} {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
`;

const games = [
	{
		title      : 'Fishing Vessel of the Future',
		subHeading : 'Scottish Fisheries Museum',
		date       : '2020',
		img        : fishing
	},
	{
		title      : 'Seonadh',
		subHeading : 'Dighty Connect project',
		date       : '2019',
		img        : seonadh
	},
	{
		title      : 'Critter Comforts',
		subHeading : 'V&A Design Collection',
		date       : '2019',
		img        : critComf
	}
];

const LatestGames = () => {
	return (
		<LargeContainer id="games" glowIntensity={2}>
			<h2>OUR LATEST GAMES</h2>
			<ListWrapper>
				{games.map((e) => (
					<Box
						title={e.title}
						subHeading={e.subHeading}
						date={e.date}
						background={e.img}
					/>
				))}
			</ListWrapper>
		</LargeContainer>
	);
};

export default LatestGames;
