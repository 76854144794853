import styled from 'styled-components';
import { sizes } from '../utils';

export const SmallContainer = styled.section`
	height: fit-content;
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100%;
	background-color: var(--Primary-color-dark);
	color: white;
	text-align: center;
	${[ sizes.down('mobBreak') ]} {
		height: fit-content;
	}
	h2 {
		font-size: 2.3rem;
		font-weight: 300;
		text-shadow: ${(props) => props.glowIntensity}px 0px 0px black,
			-${(props) => props.glowIntensity}px 0px 0px black,
			0px ${(props) => props.glowIntensity}px 0px black,
			0px -${(props) => props.glowIntensity}px 0px black;
	}
`;
