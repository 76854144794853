import React from 'react';
import SEO from '../components/seo';
import Landing from "../components/landing";
import News from "../components/news";
import LatestGames from "../components/latestGames";
import Team from "../components/team";
import Contact from "../components/contact";
import WorkedWith from "../components/workedWith";

const IndexPage = () => (
	<>
		<SEO title="Reclaimer Games" />
		<Landing color={"#1E1E1E"} strength={85}/>
		<News/>
		<LatestGames/>
		<Team/>
		<Contact/>
		<WorkedWith/>
	</>
);

export default IndexPage;
