import React from 'react';
import styled from 'styled-components';
import { sizes } from './utils';

const BoxWrapper = styled.div`
	width: 290px;
	height: 290px;
	padding: 10px;
	border: 1px solid white;
	background: url(${(props) => (props.background ? props.background : '')});
	background-position: center;
	background-size: cover;
	position: relative;
	transition: all 0.2s linear;
	${[ sizes.down('mobBreak') ]} {
		margin-bottom: 35px;
		> div {
			opacity: 1;
		}
	}
	&:hover {
		border: 1px solid var(--Secondary-color);
		-webkit-box-shadow: 4px 4px 5px 0px #ff9c395c;
		-moz-box-shadow: 4px 4px 5px 0px #ff9c395c;
		box-shadow: 4px 4px 5px 0px #ff9c395c;
		> div {
			opacity: 1;
		}
	}
`;

const InnerWrapper = styled.div`
	opacity: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	transition: opacity 0.2s linear;
	z-index: 3;
	h3 {
		font-family: roboto;
		font-size: 2.2rem;
		z-index: 3;
		margin: 0;
	}
	p {
		z-index: 3;
		margin: 0;
	}
`;

const TransparentWrapper = styled.div`
	width: 100%;
	height: 100%;
	background-color: var(--Primary-color-dark);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.7;
	z-index: 0.2;
`;

const GameItem = ({ background, title, subHeading, date }) => {
	return (
		<BoxWrapper background={background}>
			<InnerWrapper>
				<h3>{title}</h3>
				<p>{subHeading}</p>
				<p>{date}</p>
				<TransparentWrapper />
			</InnerWrapper>
		</BoxWrapper>
	);
};

GameItem.defaultProps = {
	background : 'https://unsplash.it/500/500',
	title      : 'title',
	subHeading : 'subHeading',
	date       : '2020'
};

export default GameItem;
