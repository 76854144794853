import React from 'react';
import styled from 'styled-components';
import { SmallContainer } from './common/smallContainer';
import { sizes } from './utils';

import img1 from '../images/DightyConnect.jpg';
import img2 from '../images/Fisheries.jpg';

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 400px;
	margin: 0 auto;
	${[ sizes.down('xs') ]} {
		width: 300px;
	}
	img {
		object-fit: contain;
	}
`;

const WorkWith = () => {
	return (
		<SmallContainer glowIntensity={2}>
			<h2 id="workedWith">WE'VE WORKED WITH...</h2>
			<LogoWrapper>
				<img src={img1} alt="Dighty Connect" />
				<img src={img2} alt="Fisherie Museum" />
			</LogoWrapper>
		</SmallContainer>
	);
};

export default WorkWith;
