import React from 'react';
import styled from 'styled-components';
import { LargeContainer } from './common/largeContainer';
import { sizes } from './utils';

import img from '../images/Twitter.svg';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 70px;
	height: 300px;
	${[ sizes.down('mobBreak') ]} {
		height: fit-content;
	}
	h3 {
		font-size: 2.5rem;
		color: var(--Secondary-color);
		${[ sizes.down('mobBreak') ]} {
			font-size: 1.8rem;
		}
		${[ sizes.down('xxs') ]} {
			font-size: 1.5rem;
		}
	}
	h4 {
		font-size: 1.5rem;
		${[ sizes.down('mobBreak') ]} {
			font-size: 1.2rem;
		}
	}
	a {
		background-color: #1da1f2;
		border: 0;
		border-radius: 7px;
		color: white;
		padding: 7px;
		cursor: pointer;
		margin: 0 auto;
		display: flex;
		width: fit-content;
		> img {
			display: inline;
			margin: auto 0;
			height: 100%;
			width: auto;
		}
	}
`;

const Contact = () => {
	return (
		<LargeContainer glowIntensity={2}>
			<h2 id="contact">CONTACT US</h2>
			<ContentWrapper>
				<div className="email">
					<h4>Looking for your next stand-out interactive experience?</h4>
					<h4>How about an alternate-reality game to bring your engagement to the next level?</h4>
					<h4>Send your enquiries to:</h4>
					<h3>hello@reclaimergames.com</h3>
				</div>
				<div className="twitterHandle">
					<h4>Follow Us Here!</h4>
					<a href="https://twitter.com/ReclaimerGames">
						<img src={img} alt="Twitter Follow" />Follow @ReclaimerGames
					</a>
				</div>
			</ContentWrapper>
		</LargeContainer>
	);
};

export default Contact;
