import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useOnScreen } from './hooks/onScreen';
import { TweenMax, CSSPlugin, Power1 } from 'gsap';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { sizes, getInnerWidth } from './utils';

import img from '../images/LandingBG.jpg';
import img2 from '../images/mobLandingBG.jpg';
import brandImg from '../images/ReclaimerGamesLogo.png';

const plugins = [ TweenMax, CSSPlugin ];

const LandingWrapper = styled.section`
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
	height: calc(100vh - 200px);
	min-height: 670px;
	${[ sizes.down('mobBreak') ]} {
		height: calc(100vh - 100px);
	}
	> .gatsby-image-wrapper {
		position: absolute;
		z-index: 1;
		height: 100%;
		min-height: 670px;
		width: 100%;
		object-fit: cover;
		${[ sizes.down('mobBreak') ]} {
			object-position: 100% 50%;
		}
	}
`;

const InfoWrapper = styled.div`
	position: absolute;
	opacity: 0;
	z-index: 3;
	width: 100%;
	height: fit-content;
	font-size: ${(props) => (props.text ? props.fontSize : '2rem')};
	top: ${(props) => (props.text ? props.textPosition : '40%')};
	text-align: center;
	color: ${(props) => (props.text ? props.textColor : 'black')};
	h1 {
		font-family: roboto;
		text-shadow: ${(props) => props.glowIntensity}px 0px 0px black,
			-${(props) => props.glowIntensity}px 0px 0px black,
			0px ${(props) => props.glowIntensity}px 0px black,
			0px -${(props) => props.glowIntensity}px 0px black;
		${[ sizes.down('mobBreak') ]} {
			padding: 5%;
			font-size: 2rem;
		}
	}
	span {
		display: block;
		${[ sizes.down('mobBreak') ]} {
			font-size: 1.4rem;
			color: var(--Alt-text-color);
			margin-top: 70px;
		}
	}
	img {
		position: static;
		display: block;
		width: 500px;
		height: auto;
		margin: 0 auto;
		${[ sizes.down('mobBreak') ]} {
			max-width: 500px;
			width: 80%;
		}
	}
	button {
		font-size: 3rem;
		display: block;
		margin: 20px auto 0 auto;
	}
`;

const Overlay = styled.div`
	position: absolute;
	z-index: 2;
	background-color: ${(props) => (props.overlay ? props.color : 'white')};
	opacity: ${(props) => (props.overlay ? props.strength / 100 : 1)};
	margin: 0;
	padding: 0;
	width: 100%;
	height: calc(100vh - 200px);
	min-height: 670px;
	${[ sizes.down('mobBreak') ]} {
		height: calc(100vh - 100px);
	}
`;

const Landing = ({
	IMG,
	alt,
	overlay,
	color,
	strength,
	text,
	textColor,
	textPosition,
	fontSize,
	animTrigger,
	onShow
}) => {
	let landingText = useRef(null);
	const innerWidth = getInnerWidth(1);
	const mobBreakPoint = 1000;
	const [ setRef, visible ] = useOnScreen({ threshold: 0.7 });
	const [ shown, setShown ] = useState(false);
	const [ mobDisplay, setMobDisplay ] = useState(innerWidth < 1000);

	const data = useStaticQuery(graphql`
		{
			image1: file(relativePath: { eq: "LandingBG.jpg" }) {
				childImageSharp {
					fluid {
						srcSet
					}
				}
			}
			image2: file(relativePath: { eq: "mobLandingBG.jpg" }) {
				childImageSharp {
					fluid {
						srcSet
					}
				}
			}
		}
	`);

	useEffect(() => {
		const el = landingText;
		setTimeout(() => {
			if (!onShow) {
				playAnimation(el);
			}
		}, 200);
	}, []);

	useEffect(
		() => {
			if (onShow && visible && !shown) {
				playAnimation(landingText);
			}
		},
		[ visible ]
	);

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (getInnerWidth(1) < 1000) {
				setMobDisplay(true);
			} else {
				setMobDisplay(false);
			}
		});
	}, []);

	const playAnimation = (el) => {
		TweenMax.fromTo(
			el,
			0.8,
			{ opacity: 0, y: 200 },
			{ opacity: 1, y: 0, ease: Power1.easeOut }
		);
		setShown(true);
	};
	return (
		<LandingWrapper id="landing" ref={(el) => setRef(el)}>
			<InfoWrapper
				glowIntensity={0.5}
				text={text}
				textColor={textColor}
				textPosition={textPosition}
				fontSize={fontSize}
				ref={(el) => {
					landingText = el;
				}}>
				<img src={brandImg} alt="Reclaimer Games" />
				<h1>{text}</h1>
				<span>Based in Dundee, Scotland</span>
			</InfoWrapper>
			<Overlay overlay={overlay} color={color} strength={strength} />
			{/* <img src={!mobDisplay ? img : img2} alt={alt} /> */}
			{!mobDisplay ? (
				<Img fluid={data.image1.childImageSharp.fluid} />
			) : (
				<Img fluid={data.image2.childImageSharp.fluid} />
			)}
		</LandingWrapper>
	);
};

Landing.defaultProps = {
	IMG          : img,
	alt          : 'Landing Image',
	overlay      : true,
	color        : 'red',
	strength     : 25,
	text         : 'Transmedia & Interactive experience specialists',
	textColor    : 'white',
	textPosition : '15%',
	fontSize     : '2rem',
	animTrigger  : null,
	onShow       : false
};

export default Landing;
