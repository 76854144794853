import React from 'react';
import styled from 'styled-components';
import { sizes } from './utils';
import linkedIn from '../images/linkedIn.svg';
import twitter from '../images/TwitterBtn.svg';

const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 200px;
	height: 350px;
	${[ sizes.down('mobBreak') ]} {
		height: fit-content;
		margin-bottom: 20px;
	}
	h2 {
		color: var(--Secondary-color);
		margin-bottom: 20px;
		text-shadow: ${(props) => props.glowIntensity}px 0px 0px black,
			-${(props) => props.glowIntensity}px 0px 0px black,
			0px ${(props) => props.glowIntensity}px 0px black,
			0px -${(props) => props.glowIntensity}px 0px black;
	}
	p {
		font-size: 1.2rem;
		height: 60px;
		text-shadow: ${(props) => props.glowIntensity}px 0px 0px black,
			-${(props) => props.glowIntensity}px 0px 0px black,
			0px ${(props) => props.glowIntensity}px 0px black,
			0px -${(props) => props.glowIntensity}px 0px black;
	}
`;

const ProfileWrapper = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background: url(${(props) => (props.background ? props.background : '')});
	background-position: center;
	background-size: cover;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		border: 1px solid var(--Secondary-color);
		-webkit-box-shadow: 4px 4px 5px 0px #ff9c395c;
		-moz-box-shadow: 4px 4px 5px 0px #ff9c395c;
		box-shadow: 4px 4px 5px 0px #ff9c395c;
		div {
			opacity: 0.7;
		}
		img {
			opacity: 1;
		}
	}
	img {
		z-index: 3;
		opacity: 0;
		transition: opacity 0.2s linear;
		cursor: pointer;
		margin: 0;
	}
`;

const TransparentWrapper = styled.div`
	width: 100%;
	height: 100%;
	background-color: var(--Primary-color-dark);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	border-radius: 50%;
	transition: opacity 0.2s linear;
`;

const TeamItem = ({ background, name, position, btn, link }) => {
	return (
		<ItemWrapper glowIntensity={2}>
			<ProfileWrapper background={background}>
				{btn && (
					<a href={link}>
						<img
							src={btn === 'linkedIn' ? linkedIn : twitter}
							alt="LinkedIn"
						/>
						<TransparentWrapper />
					</a>
				)}
			</ProfileWrapper>
			<h2>{name}</h2>
			<p>{position}</p>
		</ItemWrapper>
	);
};

TeamItem.defaultProps = {
	background : 'https://unsplash.it/300/300',
	name       : 'Seph',
	position   : 'Experience Designer & Programmer'
};

export default TeamItem;
