import React from 'react';
import styled from 'styled-components';
import { SmallContainer } from './common/smallContainer';
import { sizes } from './utils';

const BoxWrapper = styled.div`
	width: 950px;
	height: fit-content;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #36363678;
	background-color: var(--Primary-color-dark);
	-webkit-box-shadow: 2px 2px 14px 5px rgba(0, 0, 0, 0.40);
	-moz-box-shadow: 2px 2px 14px 5px rgba(0, 0, 0, 0.40);
	box-shadow: 2px 2px 14px 5px rgba(0, 0, 0, 0.40);
	color: white;
	text-align: left;
	${[ sizes.down('mobBreak') ]} {
		width: 90%;
		height: fit-content;
		padding: 40px;
	}
	h3 {
		text-decoration: underline;
		${[ sizes.down('mobBreak') ]} {
			text-align: center;
			font-size: 1.4rem;
		}
	}
	p {
		margin: 0 40px;
		font-size: 1.3rem;
		${[ sizes.down('mobBreak') ]} {
			margin: 0;
			font-size: 1.2rem;
		}
	}
`;

const news = () => {
	return (
		<SmallContainer glowIntensity={2}>
			<h2 id="news">Latest News</h2>
			<BoxWrapper>
				<h3>Our New Game is released at Sea Change Exhibition</h3>
				<p>
					We're thrilled to have worked with the Scottish Fisheries Museum to
					realise their vision for an engaging interactive experience for their
					Sea Change exhibition that helps educate a broad audience both within
					the museum and in schools on the trade-offs involved in sustainable
					fishing.
				</p>
			</BoxWrapper>
		</SmallContainer>
	);
};

export default news;
