import React from 'react';
import styled from 'styled-components';
import { sizes } from './utils';
import { SmallContainer } from './common/smallContainer';
import Item from './teamItem';

import seph from '../images/profiles/Seph2.jpg';
import ross from '../images/profiles/Rosso2.jpg';
import niamh from '../images/profiles/Niamh2.jpg';
import ket from '../images/profiles/Ket2.jpg';

const TeamWrapper = styled.div`
	max-width: 1200px;
	padding: 0 50px;
	flex-shrink: 1;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	${[ sizes.down('mobBreak') ]} {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
`;

const team = [
	{
		img      : seph,
		name     : 'Seph',
		position : 'Experience Designer & Programmer',
		btn      : 'linkedIn',
		link     : 'https://www.linkedin.com/in/sepheus/'
	},
	{
		img      : ross,
		name     : 'Ross',
		position : 'Narrative & Game Design',
		btn      : 'linkedIn',
		link     : 'https://www.linkedin.com/in/ross-stewart-587958193/'
	},
	{
		img      : niamh,
		name     : 'Niamh',
		position : 'Art & Production',
		btn      : 'linkedIn',
		link     : 'https://www.linkedin.com/in/niamh-loughran-931103b0/'
	},
	{
		img      : ket,
		name     : 'Ket',
		position : 'Art & Social Media',
		btn      : 'twitter',
		link     : 'https://twitter.com/KetMnstr'
	}
];

const Team = () => {
	return (
		<SmallContainer glowIntensity={2}>
			<h2 id="team">THE TEAM</h2>
			<TeamWrapper>
				{team.map((e) => (
					<Item
						background={e.img}
						name={e.name}
						position={e.position}
						btn={e.btn}
						link={e.link}
					/>
				))}
			</TeamWrapper>
		</SmallContainer>
	);
};

export default Team;
